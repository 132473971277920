import { useQuery } from 'react-query';
import  { getStateConfig } from '../api/ConfigAPI';


export default function useStateConfigData() {
    return useQuery(['stateConfigData'], async () => {
      const { data } = await getStateConfig()
      .then((response) => {
        if(response){
          let data = []
          data= response?.data;
          return {data: data}
        }    
      })
      return data;
    }, {enabled: true});
  }