import React, { useContext, useState} from "react";
import { Grid, Typography, Tab, Tabs, Box, Button, CircularProgress } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { NotificationImportant, WbTwilight, AcUnit } from "@mui/icons-material";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import DateRangePicker from "../../DateRangePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {createStateConfig} from "../../../api/ConfigAPI";
import  StatePoliciesTable from "../components/StateSeasonPoliciesTable";
import SeasonFundingTable from "../components/StateSeasonFundingTable";
import useStateConfigData from "../../../hooks/useStateConfigData";
import useAgencyData from "../../../hooks/useAgencyData";
import { AuthContext } from "../../../context/authContext";
import ConfirmationDialog from "../../ConfirmationDialog";
import SuccessModal from "../../SuccessModal";
import { useMutation } from 'react-query';
import { useErrorViewer } from "../../../context/errorContext";

const useStyles = makeStyles((theme) => ({
    warning: {
        border: `.188rem solid ${theme.palette.warning.main}`,
        borderRadius: '.5rem',
        margin: '1.5rem 0',
        alignItems: 'center'
    },
    warningIcon: {
        color: theme.palette.warning.main,
        height: '2.5rem',
        width: '2.5rem',
        transform: "rotate(20deg)",
        padding: '1rem'
    },
    subtitleStyle: {
        paddingBottom: '2rem',
        marginTop: '2rem'

    },
    paddingStyle: {
        paddingBottom: '3rem',
        marginTop: '3rem'

    },
    summerIcon: {
        color: theme.palette.warning.main,
        marginRight: '0.5rem'
    },
    winterIcon: {
        color: theme.customColors.deepBlue,
        marginRight: '0.5rem'
    },
    separator: {
        padding: '1rem',
        paddingTop: '2rem',
        marginTop: '1rem',
        display: 'inline',
        alignItems: 'center'
    }
}));

export default function StateSeasonSettings() {
    const classes = useStyles();
    const { userInfo } = useContext(AuthContext);
    const { isFetching, data } = useStateConfigData();
    const { isFetching: isFetchingAgency, data: agencyData } = useAgencyData();
    const [tabIndex, setTabIndex] = useState(0);
    const handleTabChange = (event, newTabIndex) => {setTabIndex(newTabIndex)};
    const [dateRangeValue, setDateRangeValue] = useState([null, null]);
    const [validDateRange, setValidDateRange] = useState(false)
    const dateUtil = new AdapterDateFns();
    const [startTimeValue, setStartTimeValue] = React.useState(null);
    const [endTimeValue, setEndTimeValue] = React.useState(null);
    const [disableTimepicker, setDisableTimepicker] = React.useState(!startTimeValue || !endTimeValue);
    const [policiesList, setPoliciesList] = useState()
    const [fundSourcesList, setFundSourcesList] = useState()
    const [enableMissingInfoOpen, setEnableMissingInfoOpen] = useState(false);
    const [enableLaunchSeasonInfoOpen, setEnableLaunchSeasonInfoOpen] = useState(false);
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [programType, setProgramType] = React.useState("none");

    let start = dateRangeValue[0]?.toString().substr(0,25);
    let end = dateRangeValue[1]?.toString().substr(0,25);
    const enableMissingInfoText = `Verify Selected Program Type: ${programType}. Verify Selected Program Dates: ${start} to ${end}. Verify Policies and Fund Sources.`
    const setError = useErrorViewer();

    const updateSeasonPolicies = (updatedPoliciesList) => {
        setPoliciesList(updatedPoliciesList)
    }

    const updateSeasonFundSources = (updatedFundSourcesList) => {
        setFundSourcesList(updatedFundSourcesList)
    }

    const handleSeasonProgramType = (event, newSeason) => {
        setProgramType(newSeason);
    };

    const handleDateRangeSelection = (startDate, endDate, errors) => {
        setDateRangeValue([startDate,endDate])
        setValidDateRange(!errors && startDate && dateUtil.isValid(startDate) && endDate && dateUtil.isValid(startDate))
        setDisableTimepicker(false);
        //Disable and reset timepickers if no start and end date selected
        if ((!startDate && !endDate) || (!startDate && !endDate && (startTimeValue || endTimeValue))) {
                setDisableTimepicker(true);
                setStartTimeValue(null)
                setEndTimeValue(null);
        }
    }

    const handleTimeStartSelection = (startTime) => {
        setStartTimeValue(startTime)
        let startDate = dateRangeValue[0];
        let endDate = dateRangeValue[1];
        startDate?.setHours(startTime.$H, startTime.$m, startTime.$s)
        setDateRangeValue([startDate,endDate ? endDate : null ])
        setValidDateRange(startDate < endDate)
    }

    const handleTimeEndSelection = (endTime) => {
        setEndTimeValue(endTime)
        let startDate = dateRangeValue[0];
        let endDate = dateRangeValue[1];
        endDate?.setHours(endTime.$H, endTime.$m, endTime.$s)
        setDateRangeValue([startDate ? startDate : null, endDate])
        setValidDateRange(startDate < endDate)
    }

    const handleLaunchSeasonConfirm = (policies, fundSources, programType, dateRangeValue) => (
        <span> 
        {`Launching the season will make the LIHEAP ${programType} program application open from ${dateRangeValue[0]?.toString().substr(0,25)} to ${dateRangeValue[1]?.toString().substr(0,25)}. Are you sure you wish to launch this season?`}
        <ul>
        <b>{`POLICIES`}</b>
        {policies?.map(policy => (
            <li key={policy.id}>
            <span>{policy.policyName}</span>
            </li>
        ))}
        </ul>
        <ul>
        <b>{`FUND SOURCES`}</b>
            {fundSources?.map(fund => (
            <li key={fund.id}>
                <span>{fund.fundName}</span>
            </li>
            ))}
        </ul>
        </span>
    );

const submitNewSeason = useMutation(stateConfigSeasonData => {
        return createStateConfig(stateConfigSeasonData);
        })


const handleSuccess= () => {
    setSuccessModalOpen(true)
    };

const handleSubmitConfirm = () =>{
    let newStateConfig = {
        "fundSources": fundSourcesList,
        "createdDate": new Date(Date.now()).toISOString(),
        "endDate": dateRangeValue[1],
        "createdBy": userInfo.id,
        "recordType": userInfo.agencyState,
        "policies": policiesList,
        "state": userInfo.agencyState,
        "startDate": dateRangeValue[0],
        "programType": programType,
    }
    setLoading(true)
    submitNewSeason.mutate(newStateConfig, {
        onSuccess: () => {
            handleSuccess();
            setLoading(false);
        },
        onError: (err) => { setError(err.response?.data ?? "An error occurred creating a new season."); }
    })
}

    const handleSubmitLaunchSeason = () => {
        if (!fundSourcesList || fundSourcesList.length === 0 || (!dateRangeValue[0] && !dateRangeValue[1]) || (dateRangeValue[0] === null && dateRangeValue[0] === null) || !policiesList || policiesList.length === 0 || !programType)  {
            setEnableMissingInfoOpen(true)
        } else {
            setEnableLaunchSeasonInfoOpen(true)
        }
    }

    return(        
    <>
    <Grid container>
        <Box sx={{ ml: "2rem", textAlign:'left'}}>
            <Grid container item xs={12} className={classes.warning}>
                <NotificationImportant className={classes.warningIcon}/>
                <Typography variant="subtitle1">Program dates will be posted for both agencies and customers to see. Once the season begins, clients will be able to submit applications. Once it concludes, the platform will no longer accept new applications. Caseworkers will still be able to process submitted, returned and resubmitted applications. </Typography>
            </Grid>
            <Box>
                <Typography variant="h5" className={classes.subtitleStyle}>Season</Typography>
                <Tabs value ={tabIndex} onChange={handleTabChange}>
                    <Tab label="Next Season"/>
                    <Tab label="Current Season"/>
                </Tabs>
            </Box>
            <Box>
                {tabIndex===0 && (
                <Grid container>
                    <Grid item xs={12} className={classes.subtitleStyle}>
                        <Typography variant="h5">Step 1. Set Up Program Dates </Typography>
                        <Typography variant="subtitle2">Select Season</Typography>
                    </Grid>
                    <Box>
                        <ToggleButtonGroup value={programType} exclusive onChange={handleSeasonProgramType}>
                        <ToggleButton value="Summer Cooling">
                                <WbTwilight className={classes.summerIcon}/>
                                <Typography> Summer Cooling </Typography>
                            </ToggleButton> 
                            <ToggleButton value="Winter Heating">
                                <AcUnit className={classes.winterIcon} /> 
                                <Typography> Winter Heating </Typography>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Box>
                    <Grid item xs={12} className={classes.subtitleStyle}>
                        <Typography variant="subtitle2" className={classes.subtitleStyle}>Applications can be submitted from</Typography>
                        <DateRangePicker onDateRangeSelection={handleDateRangeSelection}/>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker label="Start Time" disabled={disableTimepicker} value={startTimeValue} onChange={handleTimeStartSelection}/>
                            <div className={classes.separator}>to</div>
                            <TimePicker label="End Time" disabled={disableTimepicker} value={endTimeValue} onChange={handleTimeEndSelection} />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h5">Step 2. Add Fund Sources </Typography>
                        <Typography variant="subtitle2">Update the list of fund sources by clicking on the text fields. </Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.paddingStyle}>
                    { (isFetching || isFetchingAgency) ?
                        <>        
                            <Grid item xs={12} sx={{m: 5}} style={{textAlign: "center"}}>
                                <CircularProgress color="primary" size={100} thickness={3}/> 
                            </Grid>              
                        </>
                    :   <SeasonFundingTable data={data.fundSources} agencyData={agencyData} isFetching={isFetching} isFetchingAgency={isFetchingAgency} updateSeasonFundSources={updateSeasonFundSources}/>
                    }
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h5">Step 3. Add Policies </Typography>
                        <Typography variant="subtitle2">Update the list of policies by clicking on text fields. </Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.paddingStyle}>
                    { isFetching ?                  
                        <>        
                            <Grid item xs={12} sx={{m: 5}} style={{textAlign: "center"}}>
                                <CircularProgress color="primary" size={100} thickness={3}/> 
                            </Grid>              
                        </>
                    :   <StatePoliciesTable data={data.policies} isFetching={isFetching} updateSeasonPolicies={updateSeasonPolicies}/>}
                    </Grid>
                    <SuccessModal 
                        open={enableMissingInfoOpen}
                        title={"Verify Season Information"}
                        desc={enableMissingInfoText}
                        okLabel={"Go Back"}
                        handleOk={() => setEnableMissingInfoOpen(false)}
                    />

                        <ConfirmationDialog
                        open={enableLaunchSeasonInfoOpen}
                        title={"Verify Season Launch Information"}
                        desc={handleLaunchSeasonConfirm(policiesList, fundSourcesList, programType, dateRangeValue)}
                        okLabel={"Launch Season"}
                        cancelLabel={"Go Back"}
                        handleOk={() => (handleSubmitConfirm()) (setEnableLaunchSeasonInfoOpen(false))
                        }

                        // handleOk={() => setEnableLaunchSeasonInfoOpen(true)}
                        handleCancel={() => setEnableLaunchSeasonInfoOpen(false)}
                        />
                    <Grid item xs={6} container justifyContent="end">
                        <Button 
                            variant="outlined"
                            color="primary"
                            component="span" 
                            size='large' 
                            onClick={() => handleSubmitLaunchSeason()}
                            disabled={!validDateRange}
                        >      
                            Launch Season
                        </Button>
                        { loading ? <Grid item xs={12} container justifyContent="end" sx={{m: 5}} style={{textAlign: "center"}}><CircularProgress color="primary" size={200} thickness={3}/></Grid> : null}
                    </Grid>
                </Grid>
                )}   
            </Box>
        </Box>
        <SuccessModal 
        open={successModalOpen}
        title={"Success"}
        desc={"New Season Successfully Created"}
        okLabel={"Close"}
        handleOk={() => (setSuccessModalOpen(false) (window.location.reload()))}
    />
    </Grid>
    </>
    );
}