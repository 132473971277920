import React from 'react';
import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { NotificationImportant } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
  warning: {
    border: `.188rem solid ${theme.palette.warning.main}`,
    borderRadius: '.5rem',
    margin: '1.5rem 0',
    alignItems: 'center',
    background: '#fff',
    padding: '1rem 0'
  },
  iconContainer: {
    marginRight: '1rem'
  },
  warningIcon: {
    color: theme.palette.warning.main,
    height: '2.5rem',
    width: '2.5rem',
    transform: "rotate(20deg)",
    padding: '1rem',
  }
}));

export default function ModalWarning({ description }) {
  const classes = useStyles();

  return (
      <Grid container item xs={12} className={classes.warning}>
        <Grid item xs={1} className={classes.iconContainer}>
            <NotificationImportant className={classes.warningIcon}/>
        </Grid>
        <Grid item xs={10}>
            <Typography variant="subtitle1">{description}</Typography>
        </Grid>
      </Grid>
  );
}
