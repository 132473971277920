import makeStyles from '@mui/styles/makeStyles';
import React, { useState, useEffect } from 'react';
import { Grid, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import {
    GridToolbarContainer,
    GridRowModes,
    GridActionsCellItem,
    DataGrid
} from "@mui/x-data-grid";

const useStyles = makeStyles((theme) => ({
}));

export default function SeasonFundingTable({data, agencyData, isFetching,isFetchingAgency, updateSeasonFundSources}) {
    const classes = useStyles();
    const [rows, setRows] = useState([]);
    const [rowModesModel, setRowModesModel] = useState({});
    const [agencyNames, setAgencyNames] = useState([]);
    const validAgencies = [];
    const agencyFunds = {};
    let finalTotalsRow ={};
    let calculatedTotalsRow = [];

    const getAgencies = (agencyData) => {
        let agencies = agencyData?.map((agency)=>agency.id);
        setAgencyNames(agencies);
        return agencies; 
    }

    const transformDataForGrid = (data, agencies) =>{
        agencies.forEach((agency) => {
            data.forEach((fundsource) => {
                const fundAmountPerAgency = fundsource[agency]
                if (fundAmountPerAgency && validAgencies.includes(agency)===false){
                    validAgencies.push(agency);
                    agencyFunds[agency] = 0 // get agencies but set initial amounts to 0
                }
            })
        });
        const mappedArr = data?.map((value, index) => {
            return{
                id:index,
                fundName: value.fundName ? value.fundName : value,
                fundTotal: 0, // set initial total to 0
                ...agencyFunds
            }
        });
        data = mappedArr;
        return data;
    }
    
    const getRows = (data, agencies) => {
        setRows(transformDataForGrid(data, agencies));
        getColumns();
    }

    useEffect(()=>{
        if (!isFetchingAgency && !isFetching) {
            let listAgencyNames = getAgencies(agencyData);
            getRows(data, listAgencyNames);
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, agencyData]);

    function createId() {
        let id = Math.floor(Math.random() * 100000000);
        return id;      
    }

    function EditToolbar() {
        const handleAddClick = () => {
            const id = createId();
            setRowModesModel((rowModel) => ({
                ...rowModel,
                [id]: { mode: GridRowModes.Edit, fieldToFocus: 'fundName', id: id },
            }));
            setRows((rowsToAddTo) => [...rowsToAddTo, { id: createId(), fundName:'New Fund Placeholder',fundTotal: 0, isAddedRow: true}]);
            data = rows;
            return data
            };

    return (
        <GridToolbarContainer>
            <Button color="primary" startIcon={<AddIcon />} onClick={handleAddClick}>
                Add Funding Source
            </Button>
        </GridToolbarContainer>
        );
    }

    const handleRowEditStop = (params, event) => {
        if (event.key === "Enter"){ // this is here because syntheticbaseevent "Enter" prevents default behavior and can't be reset for some reason
            event.defaultPrevented = true; // this fails silently defaultPrevented will always be true, it cannot be set to false;
            event.defaultMuiPrevented = true; //defaultMuiPrevented prevents enter keystroke from leaving editable row << which is needed
            event.isTrusted = false; //disable "enter" key method of saving input data
        }
        else{
            const updatedPolicy = Object.entries(params)[2][1];
            let updatedFundIndex = rows.findIndex(row => row.id === updatedPolicy.id);
            const updatedFundRows = [...rows];
            updatedFundRows[updatedFundIndex] = updatedPolicy;
            setRows(updatedFundRows);
            data = rows;
            setRowModesModel({ ...rowModesModel, [updatedPolicy.id]: { mode: GridRowModes.View } });
            let addAgencyFunds = [];
            agencyNames.forEach((agency) => {
                if (updatedPolicy[agency]) {
                    addAgencyFunds.push(parseInt(updatedPolicy[agency]))
                }
            });
            const sum = addAgencyFunds.reduce((prev, curr) => prev + curr, 0);
            updatedPolicy.fundTotal = sum;
            updateSeasonFundSources(updatedFundRows);
        }
    };

    const handleDeleteClick = (id) => () => {
        setTimeout(() => {
          setRows(rows.filter((row) => row.id !== id))
          updateSeasonFundSources(rows.filter((row) => row.id !== id));
        });
    };

    const handleRowModesModelChange = (currRowModesModel) => {
        setRowModesModel(currRowModesModel);
    };

    //Prepare agency totals and create grand total to fit on one row
    const createAgencyFundTotals = (rows, agencyNames) => {
        const agencyFundsTotalData = agencyNames.map((agency) => {
            const agencyTotalsPerFund = rows.map((items) => {
                if (isNaN(items[agency])){
                    return items[agency] = 0
                } else {
                    return items[agency]
                }
            }) 
            return {
                [agency] : agencyTotalsPerFund.reduce((prev, curr) => parseInt(prev) + parseInt(curr), 0)
            }        
        });

        const createTotalsRow = (fundsDataObj) => {
            let mergeTotals = Object.assign({}, ...fundsDataObj)
            finalTotalsRow = mergeTotals;
            const grandTotal = rows.map((item) => parseInt(item.fundTotal)).reduce((prev, curr) => parseInt(prev) + parseInt(curr), 0);
            finalTotalsRow.grandTotal = grandTotal;
            calculatedTotalsRow = [finalTotalsRow];
        }

        finalTotalsRow = createTotalsRow(agencyFundsTotalData)
    }; 

    const getTotalsColumns = () =>  {
        createAgencyFundTotals(rows, agencyNames);
        const totalsColumns = agencyNames.map((agency, index) => {
            return {
                field: agency,
                flex: .055,
                type: 'number',
                renderHeader: () => (<Typography variant='subtitle2' className={classes.column}>{agency}</Typography>),
                renderCell: (params) => (<Typography variant='h7' className={classes.numberCell}>{ params.value}</Typography>)

            }
        });
            totalsColumns.unshift(                        
                {
                field: 'label',
                flex: .095,
                renderHeader: () => (<Typography variant='subtitle2' className={classes.column}>Total Funds Per Agency</Typography>),
                renderCell: (params) => (<Typography variant='h10' className={classes.numberCell}>$, per Agency</Typography>)
                }
            )
            totalsColumns.push(                        {
                field: 'grandTotal',
                type: 'number',
                flex: .055,
                renderHeader: () => (<Typography variant='subtitle2' className={classes.column}>Grand Total, $</Typography>),
                renderCell: (params) => (<Typography variant='h7' className={classes.numberCell}>{ params.value}</Typography>)
                }
            )

        if (totalsColumns.length> 0) {
            return totalsColumns
        }
        else getTotalsColumns()
    };

    const getColumns = () =>  {
        const agencyColumns = agencyNames.map((agency) => {
            return {
                field: agency,
                flex: .075,
                editable: true,
                type: 'number',
                renderHeader: () => (<Typography variant='subtitle2' className={classes.column}>{agency}</Typography>),
                renderCell: (params) => (<Typography variant='h7' className={classes.numberCell}>{ params.value}</Typography>)
            }
        });
        agencyColumns.unshift(
            {
                field: 'fundName', flex: .2, editable: true,
                renderHeader: () => (<Typography variant='subtitle2' className={classes.column}>Fund</Typography>),
                renderCell: (params) => (<Typography variant='h7' className={classes.numberCell}>{params.value}</Typography>)            
            },
        )
        agencyColumns.push(                        
            {
            field: 'fundTotal',
            type: 'number',
            renderHeader: () => (<Typography variant='subtitle2' className={classes.column}>Total</Typography>),
            renderCell: (params) => (<Typography variant='h7' className={classes.numberCell}>{ params.value}</Typography>)
            },
            {
                field: "actions",
                type: "actions",
                flex: .050,
                cellClassName: "actions",
                renderHeader: () => (<Typography variant='subtitle2' className={classes.column}>Remove</Typography>),
                getActions: ({ id, params}) => {
                return [
                <GridActionsCellItem
                    icon={<DeleteIcon/>}
                    onClick={handleDeleteClick(id, params)}
                    color="inherit"
                    label="Remove"
                />
                ];
            }}
        )

        if (agencyColumns.length> 0) {
            return agencyColumns
        }
        else getColumns()
    };

    return (
        <div>
        <Grid item xs={12} sx={{height: "30rem"}}>
            <DataGrid
                columns={getColumns()}
                disableColumnMenu={true}
                rows={rows?rows:[]} 
                editMode="row"
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                components={{
                    Toolbar: EditToolbar
                }}
                componentsProps={{
                    Toolbar: { setRows, setRowModesModel }
                }}
            />
        </Grid>
        <br></br>
        <Grid item xs={12}>
            <Typography variant="subtitle2">This table will auto-populate total dollar amount of fund sources per agency.</Typography>
        </Grid>
        <Grid item xs={12} sx={{height: "5.5rem", backgroundColor:"lightgray"}}>
            <DataGrid
                columns={getTotalsColumns()}
                disableColumnMenu={true}
                rows = {calculatedTotalsRow ? calculatedTotalsRow : []}
                getRowId={(row) => 1}
                rowsPerPageOptions={[]}
                hideFooter={true}
            />
        </Grid>
        </div>
    );
}