import { APIUtils } from './APIUtils';

const getAppConfig = () => {
  return APIUtils.get("/config")
}

const getStateConfig = () => {
  return APIUtils.get(`/stateConfig`)
}

const getAgencies = () => {
  return APIUtils.get(`/stateConfig/agencies`)
}

const createStateConfig = (data) => {
  return APIUtils.post(`/stateConfig`, data)
}

export {
  getAppConfig,
  getStateConfig,
  createStateConfig,
  getAgencies
};