import makeStyles from '@mui/styles/makeStyles';
import React, { useState, useEffect } from 'react';
import { Grid, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import {
    GridToolbarContainer,
    GridRowModes,
    GridActionsCellItem,
    DataGrid
} from "@mui/x-data-grid";

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: '75rem'
    },
}));

export default function StatePoliciesTable({data, isFetching, updateSeasonPolicies}) {
    const classes = useStyles();
    const [rows, setRows] = useState([]);
    const [rowModesModel, setRowModesModel] = useState({});
    const transformDataForGrid = (data) => {
        const mappedArr = data?.map((value, index) => {
            return {
                id: index, 
                policyName: value.policyName ? value.policyName : value,
            }
        });
    data = mappedArr;
    return data;
    }

    const getRows = (data) => {
        setRows(transformDataForGrid(data));
    }

    useEffect(()=>{
        if (isFetching===false){
            getRows(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    function createId() {
    let id = Math.floor(Math.random() * 100000000);
    return id;      
    }

    function EditToolbar() {
        const handleAddClick = () => {
            const id = createId();
            setRowModesModel((rowModel) => ({
                ...rowModel,
                [id]: { mode: GridRowModes.Edit, fieldToFocus: 'policyName', id: id },
            }));
            setRows((rowAdded) => [...rowAdded, {id: createId(), policyName:'New Policy Placeholder', isAddedRow: true}]);
            data = rows;
            return data
            };
        return (
        <GridToolbarContainer>
        <Button color="primary" startIcon={<AddIcon/>} onClick={handleAddClick}>
            Add New Policy
        </Button>
        </GridToolbarContainer>
        );
    }

    //Allows for editable rows on double click
    const handleRowEditStop = (params, event) => {
        if (event.key === "Enter"){ //because syntheticbaseevent "Enter" prevents default behavior and can't be reset for some reason
            event.defaultPrevented = true; // this fails silently defaultPrevented will always be true, it cannot be set to false??
            event.defaultMuiPrevented = true; //defaultMuiPrevented prevents enter keystroke from leaving editable row << which is needed
            event.isTrusted = false; //disable "enter" key method of saving input data
        }
        else{
            const updatedPolicy = Object.entries(params)[2][1];
            let updatedPolicyIndex = rows.findIndex(x => x.id === updatedPolicy.id)
            let updatedPolicyRows = [...rows];
            updatedPolicyRows[updatedPolicyIndex] = updatedPolicy
            setRows(updatedPolicyRows)
            data = rows;
            setRowModesModel({ ...rowModesModel, [updatedPolicy.id]: { mode: GridRowModes.View } });
            updateSeasonPolicies(updatedPolicyRows);
        } 
    };
    
    const handleDeleteClick = (id) => () => {
        setTimeout(() => {
            setRows(rows.filter((row) => row.id !== id));
            updateSeasonPolicies(rows.filter((row) => row.id !== id));
        });
    };

    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const getColumns = () => {
        return [
            {
                field: 'policyName', flex: .6, editable: true,
                renderHeader: () => (<Typography variant='subtitle2' className={classes.column}>Policy Name</Typography>),
            },
            {
                field: "actions",
                type: "actions",
                flex: .1,
                cellClassName: "actions",                
                renderHeader: () => (<Typography variant='subtitle2' className={classes.column}>Remove</Typography>),
                getActions: ({ id, params}) => {
                return [
                <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label="Delete"
                    onClick={handleDeleteClick(id, params)}
                    color="inherit"
                />
                ];
            }
            }
        ];
    }

    return (
        <div className={classes.table}>
            <Grid item xs={12} sx={{height: "30rem"}}>
                <DataGrid
                    columns={getColumns()}
                    rows={rows?rows:[]} 
                    editMode="row"
                    rowModesModel={rowModesModel}
                    onRowModesModelChange={handleRowModesModelChange}
                    onRowEditStop={handleRowEditStop}
                    components={{
                        Toolbar: EditToolbar
                    }}
                    componentsProps={{
                        Toolbar: { setRows, setRowModesModel }
                    }}
                />
            </Grid>
        </div>
    );
}